// Packages
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
  Card,
} from "reactstrap";
import axios from "axios";
import UserMetadataForm from "../components/forms/user-metadata";
// Utils
import { calculateAge, AGE_LIMITS, catchHandler, getRequestHeaders } from "../utils";
import { validationSchemaUserMetadata } from "../components/forms/schemas/user-metadata";
// Styling
import "./Profile.css";

const Profile = React.memo((props) => {
  const rootPathname = useMemo(() => "/", []);
  const [loading, setLoading] = useState(true);
  const [validated, setValidated] = useState(false);
  const [userMetadata, setUserMetadata] = useState(null);
  const [ageInMonths, setAgeInMonths] = useState(false);
  const [membershipId, setMembershipId] = useState(null);
  const history = useHistory();
  const catchHandlerBinded = catchHandler.bind(null, history);
  const formRef = useRef(null);
  const inputNotarialAgreementRef = useRef(null);
  const inputIdentityCardRef = useRef(null);

  useEffect(() => {
    axios
      .get(`/api/users/get-profile`, getRequestHeaders())
      .then((res) => {
        const { data = {} } = res;
        const { birthDate = "", membershipId = "", metadata = {} } = data;

        setUserMetadata(metadata);
        setMembershipId(membershipId);
        if (birthDate) {
          const [years, months] = calculateAge(birthDate);
          setAgeInMonths(years * 12 + months);
        } else {
          setAgeInMonths(false);
        }
      })
      .catch(catchHandlerBinded)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();

      setValidated(true);

      const formValues = formRef.current.values;
      formRef.current.validateForm();

      try {
        validationSchemaUserMetadata.validateSync(formValues);

        setLoading(true);

        axios
          .patch(
            `/api/users/edit-profile`,
            { metadata: formValues },
            getRequestHeaders()
          )
          .then(() => {
            validationSchemaUserMetadata;
            toast.success("Profilul a fost modificat cu succes");
            history.push(rootPathname);
          })
          .catch(catchHandler)
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        // Form is invalid
      }
    },
    [formRef, history, rootPathname]
  );

  const onChangePasswordClick = useCallback(() => {
    history.push("/change-password");
  }, [history]);

  const onUploadClick = useCallback(
    (notarialAgreementFlag) => {
      if (notarialAgreementFlag === true) {
        if (inputNotarialAgreementRef.current) {
          inputNotarialAgreementRef.current.click();
        }
      } else {
        if (inputIdentityCardRef.current) {
          inputIdentityCardRef.current.click();
        }
      }
    },
    [inputNotarialAgreementRef, inputIdentityCardRef]
  );

  const onFilesInput = useCallback((event, notarialAgreementFlag) => {
    const { target = {} } = event;
    const { files } = target;

    if (!files?.length) return;

    // verify files
    for (let file of files) {
      const { type } = file;
      // pdf|jpeg|jpg|png limit - sync with server
      if (!type.startsWith("image/") && type !== "application/pdf") {
        toast.warning("Poti incarca doar fisiere PDF sau Imagini");
        return;
      }

      // 20Mb limit - sync with server
      const maxFileSizeInMB = 20;
      const maxFileSizeInB = 1024 * 1024 * maxFileSizeInMB;

      if (file.size > maxFileSizeInB) {
        toast.warning("Poti incarca doar de maxim 20Mb");
        return;
      }
    }

    const formData = new FormData();
    for (let file of files) {
      formData.append("files", file);
    }

    let requestPromise = null;

    if (notarialAgreementFlag === true) {
      requestPromise = axios.patch(
        "/api/users/edit-notarial-agreement",
        formData,
        getRequestHeaders({ requestHasFilesFlag: true })
      );
    } else {
      requestPromise = axios.patch(
        "/api/users/edit-identity-card",
        formData,
        getRequestHeaders({ requestHasFilesFlag: true })
      );
    }

    if (requestPromise) {
      requestPromise
        .then(() => {
          toast.success("Documentul a fost salvat cu succes");
        })
        .catch((err) => {
          catchHandler(null, err);
        });
    }
  }, []);

  return (
    <Container className="mt-5 mb-5">
      <Row>
        <Col sm="12" lg={{ size: 10, offset: 1 }}>
          <Card className="p-5 bg-light shadow-sm">
            <h2 className="mb-5 mx-auto text-center">Administrare Profil {membershipId && `(ID: ${String(membershipId).padStart(7, "0")})`}</h2>

            {userMetadata ? (
              <UserMetadataForm
                formRef={formRef}
                initialValues={userMetadata}
                validated={validated}
                disabledPrimaryFields
              >
                <FormGroup className="d-flex justify-content-between mt-5">
                  <div className="button-container-left">
                    <Button
                      type="submit"
                      color="primary"
                      onClick={onSubmit}
                      disabled={loading}
                    >
                      Salvare
                    </Button>
                  </div>
                  <div className="button-container-right">
                    <Input
                      innerRef={inputNotarialAgreementRef}
                      type="file"
                      name="files"
                      accept="application/pdf"
                      style={{ display: "none" }}
                      onChange={(event) => onFilesInput(event, true)}
                    />
                    {ageInMonths !== false &&
                      ageInMonths >= AGE_LIMITS.UNDER &&
                      ageInMonths < AGE_LIMITS.MINOR && (
                        <Button
                          color="info"
                          onClick={onUploadClick.bind(null, true)}
                        >
                          Incarcare Acord notarial
                        </Button>
                      )}
                  </div>
                  <div className="button-container-right">
                    <Input
                      innerRef={inputIdentityCardRef}
                      type="file"
                      name="files"
                      accept="application/pdf"
                      style={{ display: "none" }}
                      onChange={onFilesInput}
                    />
                    <Button color="info" onClick={onUploadClick}>
                      Incarcare Carte de identitate
                    </Button>
                  </div>
                  <Button color="warning" onClick={onChangePasswordClick}>
                    Schimbare Parola
                  </Button>
                </FormGroup>
              </UserMetadataForm>
            ) : null}
          </Card>
        </Col>
      </Row>
    </Container>
  );
});

export default Profile;
